import { z } from "zod";
import { canvasElementBaseSchema } from "./canvas-element";

export const liveIntegrationSchemaInternal = z.object({
  type: z.literal("liveIntegration"),
  viewType: z.enum(["stack", "frame"]).default("stack"),
  integrationId: z.string().optional(),
  filters: z.array(z.object({ columnId: z.string(), columnValues: z.array(z.string()) })).optional(),
  items_order: z.array(z.string()).optional(),
  width: z.number().optional(),
  height: z.number().optional(),
  title: z.string().optional(),
});

export const liveIntegrationSchema = canvasElementBaseSchema.merge(liveIntegrationSchemaInternal);

export type LiveIntegrationElement = z.infer<typeof liveIntegrationSchema>;

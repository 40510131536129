
export type Predicate<T> = (value: T) => boolean;
export type PartialSome<T, Keys extends keyof T> = Partial<Pick<T, Keys>> & Omit<T, Keys>;

/**
 * Count occurences of item in iterable sequence.
 * Automatically curries, so you can give just first parameter and get back a function that
 * accepts sequence and returns count.
 * @param itemOrPredicate - item or function to test for item
 * @param seq - the iterable
 * @returns number
 */
export function count<T>(itemOrPredicate: (T | Predicate<T>), seq: Iterable<T>) {
  let result = 0;
  if (itemOrPredicate instanceof Function) {
    for (const item of seq)
      if (itemOrPredicate(item))
        result++;
  } else {
    for (const item of seq)
      if (item == itemOrPredicate)
        result++;
  }
  return result;
}

export function countFn<T>(itemOrPredicate: (T | Predicate<T>)) {
  return (seq: Iterable<T>) => count(itemOrPredicate, seq);
}
import { WriteTransaction } from "@workcanvas/reflect";
import { CanvasSetting, CanvasSettingsSchema, settingsSchema } from "./schemas/canvas-settings";
import { authenticatedPut } from "../util/utils";

export const canvasSettingsKey = "cSettings";

export async function updateSetting(tx: WriteTransaction, { setting, value }: { setting: CanvasSetting; value: any }) {
  const current = (await tx.get(canvasSettingsKey)) as CanvasSettingsSchema;
  try {
    const newSettings: CanvasSettingsSchema = settingsSchema.parse({
      ...current,
      [setting]: value,
      lastModifiedTimestamp: Date.now(),
    });
    return authenticatedPut(tx, canvasSettingsKey, newSettings);
  } catch (error) {
    console.error("Settings failed zod.parse");
    throw error;
  }
}

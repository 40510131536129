import { z } from "zod";

const settingsSchema = z.object({
  showCursors: z.boolean().default(true),
  lastModifiedTimestamp: z.number().optional(),
});

type CanvasSettingsSchema = z.infer<typeof settingsSchema>;

export { settingsSchema };
export type { CanvasSettingsSchema };
export type CanvasSetting = keyof Omit<CanvasSettingsSchema, "lastModifiedTimestamp">;

import consts from "../../consts";
import { z } from "zod";
import { textEnabledSchema } from "./textEnabled";
import { canvasElementBaseSchema } from "./canvas-element";
import { IntegrationType } from "../../integrations/integration";

export enum MindmapNodeOrientation {
  HORIZONTAL = "horizontal",
  VERTICAL = "vertical",
}

export enum MindmapNodeDirection {
  TRAILING = 0,
  LEADING = 1,
}

export const mindmapOrgChartNodeSchema =
  z.object({
    type: z.literal(consts.CANVAS_ELEMENTS.MINDMAP_ORG_CHART),
    x: z.number(),
    y: z.number(),
    width: z.number(),
    height: z.number(),
    rootId: z.string(),
    selfId: z.string(),
    parentId: z.string().nullish(),
    direction: z.nativeEnum(MindmapNodeDirection).optional(),
    sortIndex: z.number(),
    color: z.string().optional(),
    orientation: z.nativeEnum(MindmapNodeOrientation),
    collapsed: z.boolean().optional(),
    integrationId: z.string().optional(),
    integrationType: z.nativeEnum(IntegrationType).optional(),
    configuration: z.any().optional(),
    job_title: z.string().optional(),
    text: z.string().optional(),
    expandedNodes: z.string().array().optional(),
    selectedFields: z.string().array().optional(),
    columnValues: z.any().optional(),
    selectedColorFieldId: z.string().optional(),
    pusherTimestamp: z.string().optional(),
    childrenColumnValues: z.any().optional(),
    selectedFilter: z.any().optional()
  });

const mindmapSchemaInternal = mindmapOrgChartNodeSchema.merge(canvasElementBaseSchema);

export type MindmapOrgChartNodeElement = z.infer<typeof mindmapSchemaInternal>;

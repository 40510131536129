import { stringToPlan, useCheckout } from "frontend/billingUtils";
import { Action, DispatchFunc } from "frontend/state/actions";
import { deleteCookie, readCookieValue } from "frontend/utils/cookie-utils";
import consts, { Plan } from "shared/consts";
import { dateDiffInDays } from "shared/util/billing";
import { checkAuthStatus, createFreePlan, getUser } from "frontend/api";
import { reloadBoards } from "./boardsService";
import { reloadTeams } from "./teamsService";
import tracking from "../tracking";

export async function initUserData(dispatch: DispatchFunc) {
  try {
    const authenticated = await checkAuthStatus();
    if (!authenticated) {
      throw new Error("User not authenticated");
    }

    let user = await getUser();
    if (!user) {
      const selectedPlanId = readCookieValue(consts.COOKIE_NAMES.CANVAS_PLAN_ID);
      if (selectedPlanId && (stringToPlan(selectedPlanId) === Plan.pro || stringToPlan(selectedPlanId) === Plan.team)) {
        //if the user chose a priced package before signup, take him straight to checkout page
        deleteCookie(consts.COOKIE_NAMES.CANVAS_PLAN_ID, "/", ".workcanvas.com"); //if a user don't want to pay during checkout, let him go back to site with a free plan
        useCheckout(stringToPlan(selectedPlanId)!);
        return;
      } else {
        //create free canvas plan
        user = await createFreePlan().then(getUser);
        updateUser(user);
      }
    }

    const userDailyValue = consts.PRICING.PRO.VALUE / 30;
    const now = new Date();
    const retentionDays = dateDiffInDays(new Date(user?.canvasCreatedDate!), now);
    if (retentionDays >= 1) {
      const retentionValue = retentionDays * userDailyValue;
      tracking.reportGoogleAnalyticsConversion(consts.GA_CONVERSION_TYPES.RETURNING_USER, retentionValue);
    }
    updateUser(user);
  } catch (e) {
    dispatch({ type: Action.FailedLoadingUser, payload: null });
    throw e;
  }

  function updateUser(user: any) {
    dispatch({ type: Action.UpdatedUser, payload: user });
    reloadBoards(dispatch);
    reloadTeams(user, dispatch);
  }
}

import { z } from "zod";
import { canvasElementBaseSchema } from "./canvas-element";

const cardStackSchemaInternal = z.object({
  type: z.literal("cardStack"),
  title: z.string(),
  width: z.number(),
  height: z.number(),
});

export const cardStackSchema = canvasElementBaseSchema.merge(cardStackSchemaInternal);

export type CardStack = z.infer<typeof cardStackSchema>;

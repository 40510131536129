import consts from "../../consts";
import { z } from "zod";
import { canvasElementBaseSchema } from "./canvas-element";

export const NAME_ID = 'name';
export const ROLE_ID = 'role';
export const PHOTO_ID = 'photo';
export const DRAW_PROPS_ID = '_draw';

export enum OrgChartOrientation {
  TopToBottom = "TB",
  LeftToRight = "LR",
}

export enum OrgChartViewType {
  Default,
  NoProfilePicture
}

const orgChartFieldSchema = z.object({
  id: z.string(),
  type: z.string().default('string'),
  name: z.string(),
  hidden: z.boolean().default(false),
});

const orgChartNodeDataSchema = z.record(z.any());

export const orgChartSchema =
  z.object(
    {
      type: z.literal(consts.CANVAS_ELEMENTS.ORG_CHART),
      orientation: z.nativeEnum(OrgChartOrientation).default(OrgChartOrientation.TopToBottom),
      anchor: z.union([z.literal('root'), z.literal('topleft')]).default('root'),
      viewType: z.nativeEnum(OrgChartViewType).optional().default(OrgChartViewType.Default),
      // there are standard fields: name, role, photo; and user-added fields
      extraFields: z.record(orgChartFieldSchema),
      orderOfFields: z.array(z.string()),
      layout: z.record(
        z.object(
          {
            id: z.string(),
            collapsed: z.boolean().default(false),
            childrenIds: z.array(z.string()),
          })),
      data: z.record(orgChartNodeDataSchema),
    }
  ).merge(canvasElementBaseSchema);

export const MAX_ORG_CHART_NODES_FOR_BASIC = 10;
export type OrgChartField = z.infer<typeof orgChartFieldSchema>;
export type OrgChartElement = z.infer<typeof orgChartSchema>;
import { Point } from "./schemas";
import { MindmapNodeElement, MindmapNodeOrientation, mindmapNodeSchema } from "./schemas/mindmap";
import { canvasElementPrefix, createElementId, dbKey, getUnixTimestampUTC, textEnabledDefaults } from "../util/utils";
import consts from "../consts";
import { ReadTransaction } from "@workcanvas/reflect";

export function placeMindMapNode({ x, y }: Point, rootId?: string, parentId?: string) {
  const id = createElementId();
  const fullId = dbKey(id, "mindmap");
  const nodeSize = parentId ? consts.DEFAULTS.MINDMAP_NODE_SIZE : consts.DEFAULTS.MINDMAP_ROOT_SIZE;
  return {
    id,
    node: mindmapNodeSchema.parse({
      type: "mindmap",
      x,
      y,
      ...nodeSize,
      nodes: [],
      orientation: MindmapNodeOrientation.VERTICAL,
      zIndexLastChangeTime: getUnixTimestampUTC(),
      rootId: rootId ?? fullId, // if rootId is not provided, this is the root
      parentId,
      sortIndex: 0,
      collapsed: false,
      ...textEnabledDefaults,
    }) as MindmapNodeElement,
  };
}

export async function getMindmapNodesForRootId(tx: ReadTransaction, { rootId }: { rootId: string }) {
  const mindmapNodes = await tx
    .scan({ prefix: canvasElementPrefix + "mindmap" })
    .entries()
    .toArray();
  return mindmapNodesForData(mindmapNodes, rootId);
}

export function mindmapNodesForData(mindmapNodes: any[], rootId: string) {
  const nodes: { [key: string]: MindmapNodeElement } = mindmapNodes
    .filter(([id, node]) => {
      const element = node as MindmapNodeElement;
      return element.hidden !== true && element.rootId === rootId;
    })
    .reduce((acc, [id, node]) => {
      const [, type, nodeId] = id.split("-");
      const newId = `${type}-${nodeId}`;
      acc[newId] = node as MindmapNodeElement;
      return acc;
    }, {});
  const nodesSizes = Object.entries(nodes).reduce((acc, [id, node]) => {
    acc[id] = { width: node.width, height: node.height };
    return acc;
  }, {} as { [key: string]: { width: number; height: number } });
  return { nodes, nodesSizes };
}
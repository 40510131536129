import { z } from "zod";

const timestampSchema = z.number().positive().int();  // timestamp as returned from Date.now()
const secondsSchema   = z.number().positive().int();  // number of seconds to count duration
const usersIdsList    = z.array(z.string());

enum UserVotingStatus {
  Unknown,
  Joined,
  Declined,
  Finished
};
const UserVotingStatusEnum = z.nativeEnum(UserVotingStatus);

// objects describing users that voted. We have either canvas-users or anonymous guests.
const canvasUserSchema = z.object({
  type   : z.literal("known"),
  status : UserVotingStatusEnum,
  id     : z.string()
})
const guestUserSchema = z.object({
  type   : z.literal("anonymous"),
  status : UserVotingStatusEnum,
  name   : z.string(),
  avatar : z.string(),
})

const voterInfoSchema = canvasUserSchema.or(guestUserSchema);

const votingSessionConfigSchema = z.object({
  ownerId           : z.string(),
  title             : z.string(),
  start             : timestampSchema,
  duration          : secondsSchema.optional(),
  maxVotesPerChoice : z.number().int().positive().default(1),
  maxVotesPerUser   : z.number().int().positive().default(1),
  elementsIds       : z.array(z.string()).optional(),           // the elements we're voting on (if null, all elements in board)
  anonymous         : z.boolean().optional().default(false),    // do we display the results in the gui
})

const votingSessionSystemDataSchema = z.object({
  votersInfo        : z.record(voterInfoSchema),        // user-key -> basic info
  votesCast         : z.record(usersIdsList),           // Record: element-id -> [user-id1, user-id2, user-id3, ...]
  hidden            : z.boolean().default(false),
  itemsThumbnails   : z.record(z.string())              // thumbnails of the canvas elements voted on
});

const votingSessionSchema = votingSessionConfigSchema.merge(votingSessionSystemDataSchema);
type  VotingSession       = z.infer<typeof votingSessionSchema>;
type  VotingSessionConfig  = z.infer<typeof votingSessionConfigSchema>;
type  VoterInfo           = z.infer<typeof voterInfoSchema>;

export { votingSessionSchema, UserVotingStatus };
export type { VotingSession, VotingSessionConfig, VoterInfo };
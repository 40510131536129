import { z } from "zod";
import { canvasElementBaseSchema } from "./canvas-element";

export const baseTimelineSchemaInternal = canvasElementBaseSchema.merge(
  z.object({
    type: z.literal("timeline"),
    startDate: z.number(),
    endDate: z.number(),
    startTitle: z.string().optional(),
    endTitle: z.string().optional(),
    granularity: z.enum(["day", "week", "month"]).default("day"),
  })
);

export const timelineSchema = baseTimelineSchemaInternal.merge(
  z.object({
    itemsDates: z.record(z.number()).default({}),
  })
);

export const liveTimelineSchema = baseTimelineSchemaInternal.merge(
  z.object({
    integrationId: z.string(),
    dateColumnId: z.string(),
    filters: z.array(z.object({ columnId: z.string(), columnValues: z.array(z.string()) })).optional(),
  })
);

export type StaticTimelineElement = z.infer<typeof timelineSchema>;
export type LiveTimelineElement = z.infer<typeof liveTimelineSchema>;

export type TimelineElement = StaticTimelineElement | LiveTimelineElement;

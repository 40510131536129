import consts from "../../shared/consts";

type IndexedDbRequest = {
  objectStore: string;
};

export type IndexedDbDeleteRequest = IndexedDbRequest & {
  key: string;
};

export type IndexedDbGetRequest = IndexedDbRequest & {
  key: string;
  index?: string;
};

export type IndexedDbAddRequest = IndexedDbRequest & {
  value: any;
};

// Local storage
export function setLocalStorageItem(key: any, value: any, ttlInDays: number = 365) {
  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  var timestamp = new Date();
  var expirationTimeStamp = new Date();
  expirationTimeStamp.setHours(timestamp.getHours() + 24 * ttlInDays);
  let item = {
    value: value,
    expirationTimeStamp: expirationTimeStamp,
  };

  localStorage.setItem(key, JSON.stringify(item));
}

export function isLocalStorageAvailable() {
  try {
    return typeof localStorage !== "undefined";
  } catch {
    return false;
  }
}

export function getLocalStorageItem(key: any) {
  try {
    let item = localStorage.getItem(key) as any;

    // if the item doesn't exist, return null
    if (!item) return null;

    item = JSON.parse(item);
    // compare the expiry time of the item with the current time
    if (item.expiry && Date.now() > item.expiry) {
      // If the item is expired, delete the item from storage and return null
      localStorage.removeItem(key);

      return null;
    }

    return item.value;
  } catch (e) {
    return null;
  }
}

export function addToLocalStorageArray(arrayName: string, value: any) {
  let existingObject = (getLocalStorageItem(arrayName) as Array<any>) || [];
  if (!existingObject.includes(value)) {
    existingObject.push(value);
    setLocalStorageItem(arrayName, existingObject, 1);
  }
}

export function removeItemFromLocalStorage(key: any) {
  localStorage.removeItem(key);
}

//Indexed-db
function initializeIndexedDb() {
  return new Promise<IDBDatabase | null>((resolve, reject) => {
    if (!window.indexedDB) {
      console.log(
        "This browser doesn't support a stable version of IndexedDB, it may affect some features such as local file preview"
      );
      return reject();
    }

    const request = window.indexedDB.open("CanvasDB");

    request.onerror = (event) => {
      console.log("Couldn't initialize IndexedDB");
      return reject();
    };
    request.onsuccess = (event) => {
      const db = (event.target! as any).result;
      return resolve(db);
    };
    request.onupgradeneeded = (event) => {
      const db = (event.target! as any).result;
      db.onerror = (event: any) => {
        // Generic error handler for all errors targeted at this database's
        // requests!
        console.error("Database error: " + event.target!.errorCode);
      };

      // check if the db already exists or is it a new device
      if (event.oldVersion < 1) {
        // the db does not exists. do initial schema creation.
        // Create an objectStore which is like a table but represeneted as an object. We're
        // going to use "elementId" as our key path because it's guaranteed to be
        // unique.
        const objectStore = db.createObjectStore(consts.INDEXED_DB.OBJECT_STORES.FILES_PREVIEWS, {
          keyPath: "elementId",
        });
        //Create an index for uppyFileId so we can get records by it
        objectStore.createIndex(consts.INDEXED_DB.INDEXEDS.UPPY_FILE_ID, consts.INDEXED_DB.INDEXEDS.UPPY_FILE_ID, {
          unique: false,
        });

        // Use transaction oncomplete to make sure the objectStore creation is
        // finished before adding data into it.
        objectStore.transaction.oncomplete = (e: any) => {
          return resolve(db);
        };
      } else {
        return resolve(db);
      }
    };
  });
}

export function addToIndexedDb(addRequest: IndexedDbAddRequest) {
  return new Promise<void>(async (resolve, reject) => {
    const db = await initializeIndexedDb();
    if (db) {
      // Store values in the newly created objectStore.
      const objectStore = db.transaction(addRequest.objectStore, "readwrite").objectStore(addRequest.objectStore);
      const request = objectStore.add(addRequest.value);
      request.onsuccess = (e: any) => {
        resolve();
      };
    } else {
      return reject("error openning an IndexedDb connection");
    }
  });
}

export function getFromIndexedDb<T>(getRequest: IndexedDbGetRequest): Promise<T | null> {
  return new Promise<T | null>(async (resolve, reject) => {
    try {
      const db = await initializeIndexedDb();
      if (db) {
        const objectStore = db.transaction(getRequest.objectStore).objectStore(getRequest.objectStore);
        const request = getRequest.index
          ? objectStore.index(getRequest.index).get(getRequest.key)
          : objectStore.get(getRequest.key);
        request.onsuccess = (e: any) => {
          return resolve(e.target.result);
        };
        request.onerror = (e: any) => {
          return resolve(null);
        };
      } else {
        return reject("error openning an IndexedDb connection");
      }
    } catch (error) {
      return reject(error);
    }
  });
}

export async function deleteFromIndexedDb(deleteRequest: IndexedDbDeleteRequest) {
  return new Promise(async (resolve, reject) => {
    try {
      const db = await initializeIndexedDb();
      if (db) {
        const request = db
          .transaction(deleteRequest.objectStore, "readwrite")
          .objectStore(deleteRequest.objectStore)
          .delete(deleteRequest.key);
        request.onsuccess = (e: any) => {
          return resolve(e.target.result);
        };
        request.onerror = (e: any) => {
          return resolve(null);
        };
      } else {
        return reject("error openning an IndexedDb connection");
      }
    } catch (error) {
      return reject(error);
    }
  });
}
